#versionIndicator {
    position: fixed;
    bottom: 5px;
    right: 10px;
    font-size: 12px;
    color: #e91e63;
    z-index: 1102;
    font-weight: bold;
}

#environmentIndicator {
    width: 100px;
    background: #e43;
    position: fixed;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 5px;
    color: #ffff;
    top: 55px;
    left: -35px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    z-index: 990;
}

.environment-notice {
    background-color: #e43;
    width: 100%;
    height: 20px;
    color: #fff;
    font-weight: bold;
}


.scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.scroll::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
    cursor: pointer;
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-track {
    background: none;
}

dt {
    width: 30%;
    display: inline-block;
    color: #2E97C8;
    font-weight: 500;
    padding-bottom: 2%;
    vertical-align: top;
}

dd {
    width: 60%;
    display: inline-block;
    font-weight: 300;
    padding-bottom: 2%;
}

.center {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.capitalize {
    text-transform: capitalize;
}

.clickable {
    cursor: pointer !important;
}

.pad-10 {
    padding: 10px;
}

.margin-10 {
    margin: 10px;
}

.stat-card {
    padding: 15px;
    border: 1px solid #e6e6e6;
    border-radius: 25px;
    box-shadow: 0 0 2px 1px #f5f5f5;
}

.stat-count {
    font-size: 2em !important;
}

.corner-aligned {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.positive-outline-btn {
    color: #017501 !important;
    border-color: #007d00 !important;
    font-weight: bold;
}

.error-outline-btn {
    color: #FF6347 !important;
    border-color: #FF6347 !important;
    font-weight: bold;
}

.warning-btn {
    background-color: #FE983C !important;
    border-color: #FE983C !important;
    color: #ffffff !important;
}

.positive {
    color: #007600 !important;
}

.negative {
    color: #f44336 !important;
}

.warning {
    color: #fe983c !important;
}

input::placeholder {
    font-size: 0.8em;
}

.elevated {
    box-shadow: 0px 0px 4px 0px #d5d5d5;
}

/* Scroll bar styling */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

body::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(240, 240, 240, 0.27);
    height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(242, 242, 242, 0.34);
    border-radius: 5px;
    height: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #6e6e6e;
    height: 8px;
}

/* Dropzone styles */
.dropzone {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 1em;
    background-color: #fff;
    border: 2px dashed #d5d5d5;
    border-radius: 5px;
    min-height: 120px;
}

.dropzone:active, .dropzone:focus {
    outline: none;
}

.CircularProgressbar {
    width: 50px !important;
}

.CircularProgressbar .CircularProgressbar-text {
    font-size: 25px !important;
    font-weight: 700 !important;
}

/*Donut chart styles */

.donutchart-track {
    fill: transparent;
    stroke: #FF6347;
    stroke-width: 26;
}

.donutchart-indicator {
    fill: transparent;
    stroke: #0dbd0d;
    stroke-width: 25;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray .3s ease;
}

.donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
}

.donutchart-text {
    font-family: 'Roboto', sans-serif;
    fill: #607580;
}

.donutchart-text-val {
    font-size: 32px;
}

.donutchart-text-percent {
    font-size: 25px;
}

.donutchart-text-label {
    margin-top: 16px;
    font-size: 12px;
}

/* Code for Animation STARTS */
@-webkit-keyframes bars {
    from {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

@keyframes bars {
    from {
        background-position: 40px 0
    }
    to {
        background-position: 0 0
    }
}

/* Code for Animation ENDS */


/* Container for Progress Bar STARTS */
.container {
    background-color: #F1F1F1;
    border-radius: 10px;
    height: 15px;
    margin-bottom: 15px;
}

/* Container for Progress Bar ENDS */


/* Progress Bar STARTS */
.progressbar {
    height: 100%;
    width: 0%;
    float: left;

}

/* Progress Bar ENDS */
.green {
    background-color: #6F0
}

.container.active .progressbar {
    -webkit-animation: bars 0.3s linear infinite;
    animation: bars 0.3s linear infinite;
}

.striped .progressbar {
    background-size: 40px 40px;
    background-image: linear-gradient(135deg, #fff 50%, transparent 50%, transparent 50%, #fff 50%, rgba(255, 255, 255, 1) 50%, transparent 50%, transparent);
    background-image: -webkit-linear-gradient(135deg, #fff 50%, transparent 50%, transparent 50%, #fff 50%, #fff 50%, transparent 50%, transparent);
}

.striped .green {
    background-image: -webkit-linear-gradient(135deg, #6C0 25%, transparent 25%, transparent 50%, #6C0 50%, #6C0 75%, transparent 75%, transparent);
    background-image: linear-gradient(135deg, #6C0 25%, transparent 25%, transparent 50%, #6C0 50%, #6C0 75%, transparent 75%, transparent);
}


/* Success Indicator */
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.infoText {
    font-size: 12px;
    color: #555555
}

.blink {
    -webkit-animation: blinker 1s linear infinite;
    animation: blinker 1s linear infinite;
}

.mt--2 {
    margin-top: -8px !important;
}

.transaction-detail-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
}

.transaction-detail-list {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scrollbar-width: none;
}

.transaction-detail-list::-webkit-scrollbar {
    display: none;
}

.transaction-detail::-webkit-scrollbar {
    display: none;
}
.wordWrap {
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
}

@-webkit-keyframes blinker {
    50% {
        opacity: 0.5;
    }
}

@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0 0px 30px #7ac142;
    }
}
